<template>
  <b-modal
    :id="`detail-counter-company-modal`"
    size="lg"
    centered
    no-close-on-backdrop
    header-bg-variant="light-info"
    hide-footer
    :title="$t('sepay.columns.details')"
  >
    <IAmOverlay :loading="loading">
      <b-row class="gap-2 pb-2 border-bottom">
        <b-col sm="12">
          <h1>Tổng giao dịch: {{ item.count }}</h1>
        </b-col>
        <b-col sm="12">
          <span>Vào</span>
          <b-progress
            :value="item.countIn"
            :max="item.count"
            show-value
            variant="success"
            animated
          />
        </b-col>
        <b-col sm="12">
          <span>Ra</span>
          <b-progress
            :value="item.countOut"
            :max="item.count"
            variant="danger"
            show-value
            animated
          />
        </b-col>
      </b-row>

      <!-- filter -->
      <b-row>

        <b-col cols="6">
          <b-form-group
            label-class="h5 py-0"
            :label="$t('sepay.columns.date')"
          >
            <b-form-datepicker
              v-model="date"
              style="font-size: 1rem;"
              :today-button="!date"
              close-button
              :locale="$i18n.locale"
              :placeholder="$t('sepay.columns.date')"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          class="d-flex align-items-center gap-1"
        >
          <b-button
            variant="info"
            @click="() => this.$emit('fetch')"
          >
            <feather-icon
              class="cursor-pointer"
              icon="SearchIcon"
              size="16"
            />
            {{ $t('search') }}
          </b-button>
          <b-button
            variant="danger"
            @click="() => {
              params.date = null
              date = ''
              this.$emit('fetch')
            }"
          >
            {{ $t('clear') }}
          </b-button>
        </b-col>
      </b-row>
      <!-- table -->
      <b-row>
        <b-table
          ref="refDataTable"
          bordered
          no-border-collapse
          sticky-header
          responsive
          show-empty
          primary-key="id"
          style="max-height: 50vh"
          :items="item.items"
          :fields="tableColumns"
          :empty-text="$t('noRecordFund')"
        >
          <!-- ANCHOR Header -->
          <template
            v-for="column in tableColumns"
            v-slot:[`head(${column.key})`]="data"
          >
            <div
              :key="column.label"
              class="text-dark text-nowrap text-center"
            >
              <template>
                {{ $t(`sepay.columns.${data.label}`) }}
              </template>
            </div>
          </template>

          <!-- ANCHOR Value -->
          <template
            v-for="column in tableColumns"
            v-slot:[`cell(${column.key})`]="{ item }"
          >
            <div
              :key="column.key"
              class="text-nowrap"
              :class="{
                'text-right': typeof item[column.key] === 'number',
              }"
            >
              {{ item[column.key] }}
            </div>
          </template>

          <!-- ANCHOR stt. -->
          <template #cell(stt)="data">
            <span class="text-right">
              {{ data.index + 1 }}</span>
          </template>

          <!-- ANCHOR date. -->
          <template #cell(date)="{ item }">
            <span class="text-right">
              {{ convertISODateTime(item.date).date }}
            </span>
          </template>

        </b-table>
      </b-row>
      <!-- paging -->
    </IAmOverlay>
  </b-modal>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import {
  BButton,
  BCol,
  BFormDatepicker,
  BFormGroup,
  BProgress,
  BRow,
  BTable,
} from 'bootstrap-vue'

import {
  convertISODateTime,
} from '@core/utils/filter'

export default {
  components: {
    BFormGroup,
    BTable,
    BFormDatepicker,
    BRow,
    BCol,
    BButton,
    BProgress,
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
  },

  props: {
    loading: {
      type: Boolean,
      required: false,
    },
    item: {
      type: Object,
      default: null,
      required: true,
    },
    params: {
      type: Object,
      default: null,
      required: true,
    },
  },

  setup(props) {
    const date = ref(null)
    const tableColumns = ref([
      { label: 'date', key: 'date' },
      { label: 'transaction', key: 'transaction' },
      { label: 'transactionIn', key: 'transactionIn' },
      { label: 'transactionOut', key: 'transactionOut' },
    ])

    watch(date, val => {
      props.params.date = val
    })

    return { date, tableColumns, convertISODateTime }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
